<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <PageHeader :title="title" class="mb-3" />
    <v-card>
      <v-row justify="end" class="mt-3 mb-2">
        <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
          <v-btn-toggle dense color="primary" group v-model="botonSeleccionado">
            <v-btn
              value="OK"
              :disabled="mostrarTablaPendiente && mostrarTablaError"
              @click="changeTab(1)"
              >Resultado OK</v-btn
            >
            <v-btn
              value="ERROR"
              :disabled="mostrarTablaPendiente && mostrarTablaOK"
              @click="changeTab(2)"
              >Resultado error</v-btn
            >
            <v-btn
              value="PENDIENTE"
              :disabled="mostrarTablaOK && mostrarTablaError"
              @click="changeTab(3)"
              >Resultado pendiente</v-btn
            >
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card>
    <!-- Data table -->
    <v-card>
      <!-- Tabla pendiente -->
      <v-data-table
        v-if="mostrarTablaPendiente"
        class="elevation-2"
        :headers="headers"
        :items="ejecucionPendiente"
        :loading="loading"
        no-data-text="No hay datos disponibles para mostrar"
        :search="searchConsulta"
        item-key="datoId"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8" md="7" sm="6" align-self="center" class="pt-5">
                <v-text-field
                  v-model="searchConsulta"
                  clearable
                  label="Buscar"
                  single-line
                  :append-icon="searchIcon"
                ></v-text-field>
              </v-col>
              <v-col cols="2" md="2" sm="2"></v-col>
              <v-col cols="2" md="3" sm="4">
                <v-btn
                  color="primary"
                  class="to-right mt-1"
                  @click="deleteScript()"
                  :disabled="ejecucionPendiente.length == 0"
                >
                  Eliminar scripts
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.resultadoPendiente`]="{ item }">
          <span :style="item.rojoResultadoPendiente ? 'color:red' : ''">
            {{ item.resultadoPendiente }}</span
          >
        </template>
      </v-data-table>
      <!-- Tabla OK -->
      <v-data-table
        v-if="mostrarTablaOK"
        class="elevation-2"
        :headers="headers"
        :items="ejecucionOK"
        :loading="loading"
        no-data-text="No hay datos disponibles para mostrar"
        :search="searchConsulta"
        item-key="datoId"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8" align-self="center" class="pt-5">
                <v-text-field
                  v-model="searchConsulta"
                  clearable
                  label="Buscar"
                  single-line
                  :append-icon="searchIcon"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
      <!-- Tabla Error -->
      <v-data-table
        v-if="mostrarTablaError"
        class="elevation-2"
        :headers="headers"
        :items="ejecucionError"
        :loading="loading"
        no-data-text="No hay datos disponibles para mostrar"
        :search="searchConsulta"
        item-key="datoId"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8" align-self="center" class="pt-5">
                <v-text-field
                  v-model="searchConsulta"
                  clearable
                  label="Buscar"
                  single-line
                  :append-icon="searchIcon"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
    <DeleteDialog
      :titleProp="titleDeleteScript"
      :openDelete.sync="showDeleteModalScript"
      :maxWidth="'37%'"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  directives: { mask },
  components: { PageHeader, GoBackBtn, DeleteDialog },
  name: "StatusEjecucionAutomatica",
  data() {
    return {
      title: "Status de ejecución automática",
      isFormValid: false,
      loading: false,
      anio: "",

      datosFechasCorte: [],
      deleteIcon: enums.icons.DELETE,
      routeToGo: "/licencias-clientes/fechas-corte",
      titleDeleteScript: "¿Desea eliminar los scripts pendientes?",
      rules: rules,
      regPendientesEjecucion: null,
      regOk: null,
      regError: null,
      soloClientesActivos: true,
      excluirClientesLicencia: true,
      tipoConsulta: null,
      searchConsulta: null,
      searchIcon: "mdi-magnify",
      headers: [
        {
          text: "Nombre de cliente",
          sortable: false,
          value: "clienteNombre",
        },
        {
          text: "Fecha Alta",
          sortable: false,
          value: "fechaAlta",
          align: "end",
        },
        {
          text: "Fecha de ejecucion",
          sortable: false,
          value: "fechaEjecucion",
          align: "end",
        },
        {
          text: "Mensaje de ejecucion",
          sortable: false,
          value: "mensajeEjecucion",
          align: "end",
        },
      ],
      mostrarTablaOK: false,
      mostrarTablaError: false,
      mostrarTablaPendiente: false,
      showDeleteModalScript: false,
      botonSeleccionado: "OK",
      clickParams: "",
      ejecucionOK: [],
      ejecucionPendiente: [],
      ejecucionError: [],
    };
  },

  created() {
    this.regPendientesEjecucion = this.$route.params.items;
    this.uploadScripts(this.regPendientesEjecucion.scriptId);
    this.clickParams = this.$route.params.clickOn;

    if (this.clickParams == "Ok") {
      this.mostrarTablaOK = true;
      this.botonSeleccionado = "OK";
    }
    if (this.clickParams == "Pendiente") {
      this.mostrarTablaPendiente = true;
      this.botonSeleccionado = "PENDIENTE";
    }
    if (this.clickParams == "Error") {
      this.mostrarTablaError = true;
      this.botonSeleccionado = "ERROR";
    }
  },
  mounted() {
    this.applyFilters;
  },
  methods: {
    ...mapActions({
      getDetalleEjecucionXFechaCorte:
        "clientesData/getDetalleEjecucionXFechaCorte",
      deleteEjecucionPendienteXScript:
        "clientesData/deleteEjecucionPendienteXScript",
      setAlert: "user/setAlert",
    }),
    changeTab(num) {
      switch (num) {
        case 1:
          this.mostrarTablaOK = true;
          this.mostrarTablaError = false;
          this.mostrarTablaPendiente = false;
          break;
        case 2:
          this.mostrarTablaOK = false;
          this.mostrarTablaError = true;
          this.mostrarTablaPendiente = false;
          break;
        case 3:
          this.mostrarTablaOK = false;
          this.mostrarTablaError = false;
          this.mostrarTablaPendiente = true;
          break;
      }
    },
    async uploadScripts(scriptId) {
      const response = await this.getDetalleEjecucionXFechaCorte(scriptId);
      this.ejecucionOK = [];
      this.ejecucionPendiente = [];
      this.ejecucionError = [];
      response.forEach((element) => {
        if (element.resultadoEjecucion == true) {
          this.ejecucionOK.push(element);
        } else if (element.resultadoEjecucion == false) {
          this.ejecucionError.push(element);
        } else {
          this.ejecucionPendiente.push(element);
        }
      });
    },
    async applyFilters() {
      this.loading = true;
      const data = {
        soloClientesActivos: this.soloClientesActivos,
        excluirLicPerpetua: this.excluirClientesLicencia,
        anioFechas: this.anio != "" ? this.anio : "",
      };
      const response = await this.fetchFechasCortePorCliente(data);
      this.datosFechasCorte = response.data.data;
      this.loading = false;
    },
    resetForm() {
      this.anio = "";
      this.soloClientesActivos = true;
      this.excluirClientesLicencia = true;
    },
    deleteScript() {
      this.showDeleteModalScript = true;
      this.idToDelete = this.regPendientesEjecucion.scriptId;
    },
    async confirmDelete() {
      try {
      const response = await this.deleteEjecucionPendienteXScript(
        this.idToDelete
      );
      if (response === true) {
        this.showDeleteModalScript = false;
        this.setAlert({
          type: "success",
          message: "Eliminado con éxito.",
        });
        this.uploadScripts();
      }
      }catch(error){
         this.showDeleteModalScript = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.no-upper-case {
  text-transform: none;
}
.fontsize {
  font-size: 12px;
}
</style>
